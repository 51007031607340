@keyframes keyframe-rotate {
    100% {transform: rotate(360deg);}
}

/* The element to apply the animation to */
.custom-rotate {
  animation-name: keyframe-rotate;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}