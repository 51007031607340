.search-report-container { position: relative; min-height: 100vh; width: clamp(300px, 98vw, 0.98 * 992px); margin: 0 auto; }
.search-report-content-wrap { padding-bottom: 200px; }
.search-report-footer { position: absolute; left: 3%; bottom: 0; width: 94%; text-align: justify; font-size: min(1.4vw, 0.014 * 992px); }

@media screen and (max-width: 600px) {
  .search-report-footer { bottom: 80px; }
}

.search-head { display: flex; justify-content: space-around; padding: 10px 3vw 5px; }
.search-head > img { display: block; flex: 0 0 23%; width: 23%; height: 23%; }
.search-head-right { flex: 0 0 77%; }
.search-head-right .search-head-signin { font-family: "Playfair Display", serif; color: rgb(21, 93, 233); font-size: min(2vw, 0.02 * 992px); margin: 4% 0 0 78%; }
.search-head-right .search-head-signin > a { color: rgb(21, 93, 233);text-decoration-line: none; }
.search-head-right p.search-head-title { font-family: times new roman,times,serif; font-weight: 700; font-size: min(5.6vw, 0.056 * 992px); text-align: right; margin: min(2vw, 0.02 * 992px) 0 1px;}
.search-head-right p.search-head-title span { font-size: 1.3em; }
.search-head-right p.search-head-subtitle { font-family: "Playfair Display", serif; font-style: italic; font-size: min(2.5vw, 0.025 * 992px); text-align: center; margin: 0; }

.ais-SearchBox { margin: 20px 0; }
.ais-SearchBox-form { display: flex; height: 46px; width: clamp(320px, 60vw, 0.60 * 992px); margin: 0 auto;}
input.ais-SearchBox-input { flex: 1 0 50%; border-radius: 6px 0 0 6px; border: 1px solid rgb(21, 93, 233); padding: 1px 10px; }
input.ais-SearchBox-input:focus { outline: none; border: 1px solid rgb(0, 40, 255); }
button.ais-SearchBox-submit { flex: 0 0 45px;  border-radius: 0 6px 6px 0; border: 1px solid rgb(21, 93, 233); background-color: rgb(21, 93, 233); cursor: pointer; }
button.ais-SearchBox-reset { display: none; }
svg.ais-SearchBox-submitIcon { width: 20px; height: 20px; fill: white; }

@media screen and (max-width: 600px) {
  .ais-SearchBox-form { width: 90%; }
}

ul.ais-Hits-list { list-style-type: none; width: clamp(320px, 60vw, 0.60 * 992px); margin: 0 auto; padding: 0px; }
li.ais-Hits-item { padding: 10px 10px; border: 1px solid #998e8e; border-radius: 6px; margin: 10px 0; }
li.ais-Hits-item > a { text-decoration: none; }
span.ais-Snippet { font-size: 14px; }
.search-no-result { width: clamp(320px, 60vw, 0.60 * 992px); margin: 0 auto; }
.search-no-result > span { color: rgb(21, 93, 233); font-weight: 700; }

@media screen and (max-width: 600px) {
  ul.ais-Hits-list { width: 90%; }
  .search-no-result { width: 90%; }
}

.ais-Pagination > ul.ais-Pagination-list { list-style-type: none; display:flex; justify-content: center; padding: 0; }
.ais-Pagination > ul.ais-Pagination-list > li { width: 15px; text-align: center; }