.my-stepper-container {
  margin-bottom: 20px;
}

.my-stepper-check {
  position: absolute;
  top: 1px;
  left: calc(50% - 8px);
  font-size: 31px;
  color: green;
  cursor: pointer;
}